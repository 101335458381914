<div class="col-12 col account-menu" fxFlex>
    <div class="col-12 col">
        <a nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
            <div fxLayout="row" fxLayoutAlign="center center">
                <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/profile.jpg" />
                <span class="username mr-12" fxHide fxShow.gt-sm>{{ name }}</span>
                <fa-icon fxHide.xs [icon]="faAngleDown"></fa-icon>
            </div>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="help()">
                    <a style="text-decoration: none">
                        <fa-icon [icon]="faQuestionCircle" class="mr-8"></fa-icon>
                        <span>Help</span>
                    </a>
                </li>
                <li nz-menu-item (click)="logout()">
                    <a style="text-decoration: none">
                        <fa-icon [icon]="faSignOutAlt" class="mr-8"></fa-icon>
                        <span>Logout</span>
                    </a>
                </li>
                <li nz-menu-item (click)="toggleDarkMode()">
                    <a style="text-decoration: none">
                        <fa-icon *ngIf="!isDarkModeActive" [icon]="faMoon" class="mr-8" style="color: darkblue"></fa-icon>
                        <span *ngIf="!isDarkModeActive">Toggle Dark Mode (Beta!)</span>
                        <fa-icon *ngIf="isDarkModeActive" [icon]="faSun" class="mr-8" style="color: yellow"></fa-icon>
                        <span *ngIf="isDarkModeActive">Toggle Light Mode</span>
                    </a>
                </li>
            </ul>
        </nz-dropdown-menu>
    </div>
</div>
