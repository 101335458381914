import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {FuseNavigationModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {DiSearchBarModule} from 'app/common/di-search-bar/di-search-bar.module';

import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {AccountWidgetComponent} from 'app/layout/components/account-widget/account-widget.component';

@NgModule({
    declarations: [AccountWidgetComponent],
    imports: [MatButtonModule, MatIconModule, FuseSharedModule, FuseNavigationModule, DiSearchBarModule, NzDropDownModule, FontAwesomeModule],
    exports: [AccountWidgetComponent]
})
export class AccountWidgetModule {}
