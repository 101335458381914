import {Injectable} from '@angular/core';
import {Document, Packer, Paragraph, Table, TableCell, TableRow, WidthType, BorderStyle} from 'docx';
import * as XLSX from 'xlsx';
import PptxGenJS from 'pptxgenjs';

@Injectable({
    providedIn: 'root'
})
export class FileConversionService {
    convertToCSV(rows: any[]): Blob {
        const headers = Object.keys(rows[0]).join(',');
        const data = rows.map((row) => Object.values(row).join(',')).join('\n');
        const csvData = `${headers}\n${data}`;
        return new Blob([csvData], {type: 'text/csv;charset=utf-8;'});
    }

    convertToDOC(rows: any[]): Promise<Blob> {
        const headers = Object.keys(rows[0]);
        const tableRows = [
            new TableRow({
                children: headers.map(
                    (header) =>
                        new TableCell({
                            children: [new Paragraph(header)],
                            borders: {
                                top: {style: BorderStyle.SINGLE, size: 1, color: '000000'},
                                bottom: {style: BorderStyle.SINGLE, size: 1, color: '000000'},
                                left: {style: BorderStyle.SINGLE, size: 1, color: '000000'},
                                right: {style: BorderStyle.SINGLE, size: 1, color: '000000'}
                            }
                        })
                )
            }),
            ...rows.map(
                (row) =>
                    new TableRow({
                        children: Object.values(row).map(
                            (cell) =>
                                new TableCell({
                                    children: [new Paragraph(cell.toString())],
                                    borders: {
                                        top: {style: BorderStyle.SINGLE, size: 1, color: '000000'},
                                        bottom: {style: BorderStyle.SINGLE, size: 1, color: '000000'},
                                        left: {style: BorderStyle.SINGLE, size: 1, color: '000000'},
                                        right: {style: BorderStyle.SINGLE, size: 1, color: '000000'}
                                    }
                                })
                        )
                    })
            )
        ];

        const table = new Table({
            rows: tableRows,
            width: {
                size: 100,
                type: WidthType.PERCENTAGE
            }
        });

        const doc = new Document({
            sections: [
                {
                    children: [table]
                }
            ]
        });

        return Packer.toBlob(doc);
    }

    convertToSheet(rows: any[]): Blob {
        const ws = XLSX.utils.json_to_sheet(rows);

        // Apply styles to the header row
        const headers = Object.keys(rows[0]);
        headers.forEach((header, index) => {
            const cellAddress = XLSX.utils.encode_cell({c: index, r: 0});
            if (!ws[cellAddress]) ws[cellAddress] = {};
            ws[cellAddress].s = {
                fill: {
                    fgColor: {rgb: 'B8CCE4'}
                },
                font: {
                    bold: true
                }
            };
        });

        // Apply styles to the data rows
        rows.forEach((row, rowIndex) => {
            Object.values(row).forEach((cell, colIndex) => {
                const cellAddress = XLSX.utils.encode_cell({c: colIndex, r: rowIndex + 1});
                if (!ws[cellAddress]) ws[cellAddress] = {};
                ws[cellAddress].s = {
                    fill: {
                        fgColor: {rgb: 'D9EAD3'}
                    }
                };
            });
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});

        return new Blob([wbout], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    }

    convertToSlide(rows: any[]): Promise<any> {
        const pptx = new PptxGenJS();
        const rowsPerSlide = 10; // Adjust this value based on your needs
        const headers = Object.keys(rows[0]);
        const tableData = [headers, ...rows.map((row) => Object.values(row).map(String))];

        let currentSlide = pptx.addSlide();
        let currentTableData = [headers.map((header) => ({text: header, options: {bold: true, fill: {type: 'solid' as const, color: 'B8CCE4'}}}))];

        tableData.slice(1).forEach((row, index) => {
            currentTableData.push(row.map((cell) => ({text: cell, options: {bold: false, fill: {type: 'solid' as const, color: 'D9EAD3'}}})));
            if (currentTableData.length === rowsPerSlide || index === tableData.length - 2) {
                currentSlide.addTable(currentTableData, {x: 0.5, y: 0.5, w: 8.5, h: 5.0});
                if (index !== tableData.length - 2) {
                    currentSlide = pptx.addSlide();
                    currentTableData = [
                        headers.map((header) => ({text: header, options: {bold: true, fill: {type: 'solid' as const, color: 'B8CCE4'}}}))
                    ];
                }
            }
        });

        return pptx.write({outputType: 'blob'});
    }
}
