import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {IPreInvoiceBatch, PreInvoiceBatch} from 'app/blocks/model/pre-invoice-batch.model';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class PreInvoiceBatchService extends EntityCrudService<IPreInvoiceBatch> {
    constructor(http: HttpClient) {
        super(http, Resource.PRE_INVOICE_BATCHS, (obj?) => new PreInvoiceBatch(obj));
    }

    approvePreInvoiceBatches = (ids: any): Promise<any> => {
        const requestParams = new HttpParams().set('batchInvoiceIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/pre-invoice-batchs/approve';
        return this.http.post<any>(REQUEST_URI, requestParams, {observe: 'response'}).toPromise();
    };

    createPreInvoiceBatches = (ids: any, date: any): Promise<any> => {
        let requestParams = new HttpParams().set('ids', ids);
        if (date !== null && date !== undefined) {
            requestParams = requestParams.set('date', date.toISOString());
        }
        const REQUEST_URI = SERVER_API_URL + 'api/pre-invoice-batchs/create';
        return this.http.post<any>(REQUEST_URI, requestParams, {observe: 'response'}).toPromise();
    };

    rejectPreInvoiceBatches = (ids: any): Promise<any> => {
        const requestParams = new HttpParams().set('batchInvoiceIds', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/pre-invoice-batchs/reject';
        return this.http
            .delete<any>(REQUEST_URI, {
                params: requestParams,
                observe: 'response'
            })
            .toPromise();
    };

    updateModifiedBatches = (updateValue): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/pre-invoice-batchs/modify';
        return this.http.post<any>(REQUEST_URI, updateValue, {observe: 'response'}).toPromise();
    };

    validateRunOrders = (ids: any): Promise<boolean> => {
        const requestParams = new HttpParams().set('ids', ids);
        const REQUEST_URI = SERVER_API_URL + 'api/pre-invoice-batchs/validate-orders';
        return this.http.post<any>(REQUEST_URI, requestParams).toPromise();
    };

    getPreviewPdf = (id: number, preview = true): Promise<any> => {
        const type = preview === true ? 'blob' : 'arraybuffer';
        const REQUEST_URI = `${SERVER_API_URL}api/pre-invoice-batchs/${id}/preview`;
        return this.http.get<any>(REQUEST_URI, {responseType: type as 'json'}).toPromise();
    };

    removeOrderFromBatch = (batchId: number, orderId: number): Promise<boolean> => {
        const REQUEST_URI = `${SERVER_API_URL}api/pre-invoice-batchs/${batchId}/remove-order/${orderId}`;
        return this.http.post<any>(REQUEST_URI, null).toPromise();
    };

    addOrdersToBatch = (batchId: number, orderIds: number[]): Promise<HttpResponse<any>> => {
        const REQUEST_URI = `${SERVER_API_URL}api/pre-invoice-batchs/${batchId}/add-orders/${orderIds}`;
        return this.http.post<any>(REQUEST_URI, null, {observe: 'response'}).toPromise();
    };
}
