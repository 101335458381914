import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class SuggestionService {
    constructor(private http: HttpClient) {}

    getSuggestion = (file: any, entity: any, type: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/gemini/file/${entity}/${type}`, file).toPromise();
    };

    getSuggestionText = (text: any, entity: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/gemini/text/${entity}`, text).toPromise();
    };

    getCommand = (file: any, commands: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/gemini/command/file/${commands}`, file).toPromise();
    };

    getCommandText = (text: any, commands: any): Promise<any> => {
        return this.http.post<any>(SERVER_API_URL + `api/gemini/command/text/${commands}`, text).toPromise();
    };

    getDbData = (prompt): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/gemini/sql/${prompt}`).toPromise();
    };

    geminiText = (prompt, data_format): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/gemini`, {
                text: prompt,
                format: data_format
            })
            .toPromise();
    };

    geminiUI = (prompt, data_format): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/gemini/ui`, {
                text: prompt,
                format: data_format
            })
            .toPromise();
    };

    geminiFile = (file, type, data_format): Promise<any> => {
        return this.http
            .post<any>(SERVER_API_URL + `api/gemini`, {
                format: data_format,
                file: file,
                type: type
            })
            .toPromise();
    };

    sendEmail = (file: Blob, fileName: string): Promise<any> => {
        const formData = new FormData();
        formData.append('file', new Blob([file]), fileName);

        return this.http.post<any>(`${SERVER_API_URL}api/gemini/email-file`, formData).toPromise();
    };

    getPrompts = (): Promise<any> => {
        return this.http.get<any>(SERVER_API_URL + `api/prompts`).toPromise();
    };

    deletePrompt = (promptId): Promise<any> => {
        return this.http.delete<any>(SERVER_API_URL + `api/prompts/${promptId}`).toPromise();
    };
}
