import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {EntityAction} from 'app/blocks/model/action.model';
import {SuggestionService} from 'app/blocks/service/api/suggestion.service';
import {Router} from '@angular/router';
import {AICommandService} from 'app/blocks/service/ai-command.service';
import {EntityResource} from 'app/blocks/resource/entity-resource';
import {AISearchService} from 'app/blocks/service/ai-search.service';
import {convertToTitleCase} from 'app/blocks/util/conversion-util';
import {AIResource} from 'app/constants/ai';

export enum InputType {
    DOCUMENT = 'DOCUMENT',
    VOICE = 'VOICE',
    IMAGE = 'IMAGE',
    TEXT = 'TEXT'
}

export enum ScreenType {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    LIST = 'LIST',
    OTHER = 'OTHER'
}
@Injectable({
    providedIn: 'root'
})
export class AIInputService {
    public entityCommands = {};
    public resourceName: string;
    public context = {};
    private navigationCompleted = new Subject<boolean>();
    public loadingText = new BehaviorSubject<string>('Deciding Command to execute...');
    public tableData = new BehaviorSubject<any>(null);
    public dialogueData = new BehaviorSubject<any>(null);
    public lastExecutedCommand = new BehaviorSubject<string>(null);
    public currentResource = new BehaviorSubject<any>(null);
    public screenType = new BehaviorSubject<string>(null);
    public searchParams = {};

    constructor(
        private suggestionService: SuggestionService,
        public aiCommandService: AICommandService,
        public _router: Router,
        private entityResource: EntityResource,
        public aiSearchService: AISearchService
    ) {
        this.aiCommandService.updateSubject.subscribe((command) => {
            this.entityCommands[command.label] = command;
        });
        for (const action of this.aiCommandService.getCommands()) {
            if (action.isAIAction !== false) {
                this.entityCommands[action.label] = action;
            }
        }
    }

    public getCommands() {
        let str = Object.keys(this.entityCommands).join(',') + '_';
        for (const resource of Object.keys(AIResource)) {
            if (resource.match(/(^(?:[a-zA-Z0-9])*)$/)) {
                str += resource + ',';
            }
        }
        return str;
    }

    public setData(resourceName: string, actions: EntityAction[], screenType): void {
        this.resourceName = resourceName;
        this.entityCommands = {};
        this.screenType.next(screenType);

        // Note Order of both for loops is important. Global Commands are overriden by Screen Level Commands
        for (const action of this.aiCommandService.getCommands()) {
            if (action.isAIAction) {
                this.entityCommands[action.label] = action;
            }
        }

        for (const action of actions) {
            if (action.isAIAction) {
                this.entityCommands[action.label] = action;
            }
        }

        if (resourceName) {
            this.aiSearchService.setScreenCommands(actions, resourceName);
        }

        this.navigationCompleted.next(true);
    }

    public async processCommands(prompt, file, commands: any[], resource: any): Promise<void> {
        resource = this.entityResource.getResource(resource);
        this.currentResource.next(resource);
        for (const command of commands) {
            await this.navigateAndProcessCommand(command, resource, prompt, file);
        }
    }

    async navigateAndProcessCommand(command: any, resource: any, prompt, file): Promise<void> {
        if (!this.entityCommands[command]) {
            return;
        }

        this.loadingText.next('Executing Command: ' + convertToTitleCase(command));

        if (resource?.name != this.resourceName) {
            await this.navigate(this.entityCommands[command].redirectOnResourceMismatch);
        }

        await this.navigate(this.entityCommands[command].navigateBefore);

        let response = await this.getCustomAIResponse(command, prompt, file);

        if (!response) {
            response = await this.getSuggestion(command, prompt, file);
        }

        if (!response) {
            response = await this.getDbData(command, prompt);
        }

        if (this.entityCommands[command].showResponseInTable) {
            this.tableData.next(response);
        }

        if (this.entityCommands[command].showResponseInDialog) {
            this.dialogueData.next(response);
        }

        if (this.entityCommands[command].action) {
            await this.processCommand(command, prompt, file, resource, response);
        }

        if (await this.navigate(this.entityCommands[command].navigateAfter)) {
            return await this.navigateAndProcessCommand(command, resource, prompt, file);
        }
        this.lastExecutedCommand.next(command);
    }

    async processCommand(command: any, prompt: string, file: any, resource: any, response: any): Promise<void> {
        const params = [];

        if (this.entityCommands[command].useInputInAction) {
            params.push(file ? file : prompt);
        }
        if (response) {
            params.push(response);
        }
        if (this.entityCommands[command].identifyPromptResource) {
            params.push(resource);
        }
        if (this.entityCommands[command].useContextInAction) {
            params.push(this.context);
        }

        if (this.entityCommands[command].useParamInAction) {
            params.push(this.getParamData());
        }

        const commandResult = await this.entityCommands[command].action(...params);

        if (this.entityCommands[command].showCommandResultInTable) {
            this.tableData.next(commandResult);
        }

        if (this.entityCommands[command].showCommandResultInDialog) {
            this.dialogueData.next(commandResult);
        }

        this.context[command] = commandResult;
    }

    getFileType(file: any): InputType {
        if (file) {
            if (file.type.includes('audio') || file.type.includes('video')) {
                return InputType.VOICE;
            } else if (file.type.includes('image')) {
                return InputType.IMAGE;
            }
        }
        return InputType.DOCUMENT;
    }

    getContext(command) {
        if (this.entityCommands[command].promptContextKeys) {
            return this.entityCommands[command].promptContextKeys.map((key) => this.context[key]);
        }
        return null;
    }

    getFileBytes(file: File): Promise<Uint8Array> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const arrayBuffer = reader.result as ArrayBuffer;
                const bytes = new Uint8Array(arrayBuffer);
                resolve(bytes);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    async navigate(url): Promise<boolean> {
        if (url && this._router.url !== url) {
            this.loadingText.next('Navigating...');
            this.navigationCompleted.next(false);
            await this._router.navigateByUrl(url);
            await this.waitForNavigation();
            return true;
        }
        return false;
    }

    async getCustomAIResponse(command: any, prompt: string, file: any): Promise<any> {
        if (this.entityCommands[command].promptFormat) {
            if (this.entityCommands[command].aiCustomPromptLoadingText) {
                this.loadingText.next(this.entityCommands[command].aiCustomPromptLoadingText);
            } else {
                this.loadingText.next('Thinking...');
            }
            if (file) {
                return await this.suggestionService.geminiFile(
                    this.getFileBytes(file),
                    this.getFileType(file),
                    this.entityCommands[command].promptFormat
                );
            } else {
                prompt = '__USER INPUT__:' + prompt;

                if (this.entityCommands[command].promptInstruction) {
                    prompt = prompt + '\n __IMPORTANT INSTRUCTION__: ' + this.entityCommands[command].promptInstruction;
                }

                if (this.getContext(command)) {
                    prompt = prompt + '\n __PREVIOUS CONTEXT__: ' + JSON.stringify(this.getContext(command));
                }

                return await this.suggestionService.geminiText(prompt, this.entityCommands[command].promptFormat);
            }
        }
        return null;
    }

    async getSuggestion(command: any, prompt: string, file: any): Promise<any> {
        const resource = this.entityCommands[command].resourceName ? this.entityCommands[command].resourceName : this.resourceName;
        console.log(this.entityCommands[command]);
        if (this.entityCommands[command].aiSuggestion && resource) {
            if (this.entityCommands[command].aiSuggestionLoadingText) {
                this.loadingText.next(this.entityCommands[command].aiSuggestionLoadingText);
            } else {
                this.loadingText.next('Thinking...');
            }
            if (file) {
                return await this.suggestionService.getSuggestion(file, resource, this.getFileType(file));
            } else {
                return await this.suggestionService.getSuggestionText(prompt, resource);
            }
        }
        return null;
    }

    async getDbData(command: any, prompt: string): Promise<any> {
        if (this.entityCommands[command].aiQuery) {
            if (this.entityCommands[command].aiQueryLoadingText) {
                this.loadingText.next(this.entityCommands[command].aiQueryLoadingText);
            } else {
                this.loadingText.next('Querying Database...');
            }
            prompt = prompt.toLowerCase().replace('query', 'select');
            return await this.getDbDataUtil(prompt);
        }
        return null;
    }

    async getDbDataUtil(prompt: string): Promise<any> {
        const replacement = {
            '/': '-'
        };
        for (const key in replacement) {
            const regex = new RegExp(key, 'g');
            prompt = prompt.replace(regex, replacement[key]);
        }
        return await this.suggestionService.getDbData(prompt);
    }

    waitForNavigation(): Promise<void> {
        return new Promise((resolve) => {
            const subscription = this.navigationCompleted.subscribe((completed) => {
                if (completed) {
                    subscription.unsubscribe();
                    resolve();
                }
            });
        });
    }

    getParamData() {
        const data = {};
        for (const key in this.searchParams) {
            if (this.aiSearchService.searchCache[key]) {
                data[key] = this.aiSearchService.searchCache[key][this.searchParams[key]];
            } else {
                data[key] = this.searchParams[key];
            }
        }
        return data;
    }
}
