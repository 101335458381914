export enum Resource {
    COMPANIES = 'company',
    CONTROL_AREAS = 'controlAreas',
    COUNTRIES = 'countries',
    CURRENCIES = 'currencies',
    PAYMENTS = 'payments',
    IMAGES = 'images',
    INVOICES = 'invoices',
    EXPENSES = 'expenses',
    HANDHELD_PROFILES = 'handheldProfiles',
    TIME_WINDOWS = 'timeWindows',
    NOTES = 'notes',
    DRIVER_MESSAGES = 'driverMessages',
    VEHICLES = 'vehicles',
    DEVIATION_REASON = 'deviationReasons',
    BANKS = 'banks',
    VEHICLE_CLASSES = 'vehicleClasses',
    SETTING_META_DATA = 'settingMetaData',
    HAULIER_ORDERS = 'haulierOrders',
    WORKFLOWS = 'workflows',
    HAULIER_ORDERS_UNPLANNED = 'haulierOrdersUnplanned',
    HAULIER_CUSTOMERS = 'haulierCustomers',
    LOCATION_POINTS = 'locationPoints',
    TRAILERS = 'trailers',
    TRAILER_TYPES = 'trailerTypes',
    ORGANISATIONS = 'organisations',
    CONTACT_INFOS = 'contactInfos',
    FUEL_SURCHARGE_GROUPS = 'fuelSurchargeGroups',
    FUEL_SURCHARGES = 'fuelSurcharges',
    EXCHANGE_RATES = 'exchangeRates',
    PERSONS = 'persons',
    SERVICE_LEVELS = 'serviceLevels',
    CUSTOM_GOODS_TYPES = 'customGoodsTypes',
    CONTAINER_TYPES = 'containerTypes',
    EXPENSE_TYPES = 'expenseTypes',
    EXPENSE_PAYMENT_METHOD = 'expensePaymentMethod',
    EVENT_TYPES = 'eventTypes',
    CHECK_LISTS = 'checkLists',
    DRIVERS = 'drivers',
    ADVANCE_QUERIES = 'advanceQueries',
    SAFETY_PROFILES = 'safetyProfiles',
    REPORTS = 'reports',
    IMPORT_DATA = 'importData',
    VEHICLE_INSPECTION_REPORTS = 'vehicleInspectionReports',
    RUNS = 'runs',
    LEGS = 'legs',
    SUB_CONTRACTOR = 'sub-contractors',
    RESOURCE_EVENTS = 'resourceEvents',
    RESOURCE_EVENT_TYPES = 'resourceEventTypes',
    DRIVER_TYPES = 'driverTypes',
    RESOURCE_ALLOCATION = 'resourceAllocations',
    RESOURCE_SCHEDULER = 'resourceScheduler',
    ORDER_SCHEDULER = 'orderScheduler',
    NOMINAL_CODES = 'nominalCodes',
    BUSINESS_TYPES = 'businessTypes',
    EXTRA_TYPES = 'extraTypes',
    EXTRAS = 'extras',
    ORGANISATION_EXTRA_TYPES = 'organisationExtraTypes',
    SCALES = 'scales',
    METRICS = 'metrics',
    TP_ASSET_PROFILES = 'tpAssetProfiles',
    TP_VEHICLE_SKILLS = 'tpVehicleSkills',
    RE_INDEX = 'reIndexs',
    TP_VEHICLE_TYPES = 'tpVehicleTypes',
    DISPLAY_SETTINGS = 'displaySettings',
    TOUR_PLANNERS = 'tourPlanners',
    PRICE_LISTS = 'priceLists',
    TRAFFIC_SHEET = 'trafficSheet',
    LEG_TRAFFIC_SHEET = 'leg-traffic-sheet',
    LAST_CALL_IN = 'lastCallIn',
    CLIENT_HAULIER_ORDERS = 'clientHaulierOrders',
    TENANT_MENU_ITEM = 'tenantMenuItem',
    TRAFFIC_AREAS = 'trafficAreas',
    PACKAGE_RETURNS = 'packageReturns',
    ATTEMPTED_DELIVERY = 'attemptedDeliveries',
    ZONE_MAPS = 'zoneMaps',
    VAT_TYPE = 'vatTypes',
    DEFAULT_VAT = 'defaultVats',
    USERS = 'users',
    UK_VAT_RATES = 'ukVatRates',
    CREDIT_NOTES = 'creditNotes',
    CUSTOMER_TRANSACTIONS = 'customerTransactions',
    TEAMS = 'teams',
    RESOURCES = 'resources',
    TRAFFIC_AREA_VIEW = 'trafficAreaView',
    HAULIER_ORDERS_COLLECTIONS = 'haulierOrdersCollections',
    HAULIER_ORDERS_DELIVERIES = 'haulierOrdersDeliveries',
    LATE_REASONS = 'lateReasons',
    TRANSLATION = 'translation',
    CLAUSED_DELIVERY_REASONS = 'clausedDeliveryReasons',
    APP_REQUESTS = 'appRequests',
    ENTITY_AUDIT = 'entityAudit',
    PODS = 'pods',
    LOCALCLIENTS = 'localClients',
    ADD_CLIENT_LOCATION = 'localClientLocation',
    ACCOUNTS_PACKAGE = 'accountsPackage',
    HM_FILES = 'hmFiles',
    DRIVER_SUB_TYPES = 'driverSubTypes',
    FLEETVIEWS = 'fleetviews',
    ORDER_GROUPS = 'orderGroups',
    MANIFESTS = 'manifests',
    PRE_INVOICE_BATCHS = 'preInvoiceBatchs',
    USER_DEFAULT = 'userDefault',
    SHIFT_PLANNER = 'shiftPlanner',
    PLANNING_ORDERS = 'planningOrders',
    POD_APPROVALS = 'podApprovals',
    REJECTION_REASONS = 'rejectionReasons',
    SHIFT_TYPES = 'shiftTypes',
    SCHEDULED_EVENTS = 'scheduledEvents',
    MULTI_BATCH_INVOICE = 'multiBatchInvoice',
    OUTSTANDING_CALL_INS = 'outstandingCallIns',
    ORDER_DETAILS = 'orderDetails',
    DOCKET_EDIT = 'docketEdit',
    ROLES = 'roles',
    SUBCONTRACTED_WORK = 'subcontractedWork',
    EXECUTIONS = 'executions',
    TENANTS = 'tenants',
    PROFILES = 'profiles',
    BACKUPS = 'backups',
    EMAIL_WORKFLOW = 'emailWorkflows',
    RESTORES = 'restores',
    LATE_COMMENT = 'lateComment',
    LOCATION_POINT_AWAITING_INTEGRATION = 'locationPointAwaitingIntegration',
    ETA_ARRIVALS = 'etaArrivals',
    TOOLBAR = 'toolbar',
    FAILED_IMPORT_LOG = 'failedImportLogs',
    FAILED_IMPORT_TASK = 'failedImportTasks',
    READY_TO_INVOICE_ORDERS = 'readyToInvoiceOrders',
    MARK_READY_FOR_INVOICE_ORDERS = 'markReadyForInvoiceOrders',
    PRICE_LIST_INCREMENT = 'priceListIncrement',
    APP_VERSION = 'appVersion',
    DB_USER = 'dbuser',
    CUSTOM_REFERENCE = 'customReferences',
    DAILY_MANIFEST = 'dailyManifest',
    RESOURCE_ICONS = 'resourceIcons',
    INTEGRATION_MAPPINGS = 'integrationMappings',
    DRIVER_REQUEST_TYPES = 'driverRequestTypes',
    DRIVER_REQUESTS = 'driverRequests',
    SYSTEM_SURCHARGE = 'systemSurcharge',
    ASYNC_REQUESTS = 'asyncRequests',
    POD_AUDIT = 'podAudit',
    DRIVER_EXPENSES = 'Driver Expenses',
    DRIVER_STRIKES = 'driverStrikes',
    MULTI_TRUNKS = 'multiTrunks',
    DRIVER_START_DAY_LOG = 'driverStartDayLog',
    DRIVER_BREAKS = 'driverBreaks',
    LOADING_SHEET = 'loadingSheet',
    PLANNER_BOARD_CARD = 'plannerBoardCards',
    GOODS_INFO = 'goodsInfo',
    DAILY_REPORT_SCHEDULES = 'dailyReportSchedules',
    OUTSTANDING_UNLOADED_ITEMS = 'outstandingUnloadedItems'
}
