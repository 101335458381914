import {Injectable, Injector, Type} from '@angular/core';
import {Resource} from 'app/constants/resource';
import { HaulierOrderResource } from 'app/blocks/resource/haulier-order-resource';

@Injectable({
    providedIn: 'root'
})
export class EntityResource {
    public resourceFactory = new Map<Resource, Type<any>>();
    constructor(private _injector: Injector) {
        this.resourceFactory.set(Resource.HAULIER_ORDERS, HaulierOrderResource);
    }

    public getResource(resource: any) {
        if (!resource || !this.resourceFactory.has(resource)) {
            return null;
        }
        return this._injector.get(this.resourceFactory.get(resource));
    }
}
