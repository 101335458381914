import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {faAngleDown, faBell, faMoon, faQuestionCircle, faSignOutAlt, faSun} from '@fortawesome/free-solid-svg-icons';
import {enable as enableDarkMode, disable as disableDarkMode, isEnabled as isDarkReaderEnabled, setFetchMethod as setFetchMethod} from 'darkreader';
import {LOGIN_API_URL} from 'app/app.constants';
import {LoginService} from 'app/core/login/login.service';
import {Principal} from 'app/core/auth/principal.service';

@Component({
    selector: 'account-widget',
    templateUrl: './account-widget.component.html',
    styleUrls: ['./account-widget.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountWidgetComponent implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;

    faAngleDown = faAngleDown;
    faSignOutAlt = faSignOutAlt;
    faQuestionCircle = faQuestionCircle;
    faBell = faBell;

    faSun = faSun;
    faMoon = faMoon;

    // Private
    private _unsubscribeAll: Subject<void>;
    isDarkModeActive = false;
    name: string;

    constructor(
        private _router: Router,
        private _loginService: LoginService,
        private _principal: Principal
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject<void>();
        this.isDarkModeActive = isDarkReaderEnabled();
        if (localStorage.getItem('isDarkModeActive') === 'true' && !this.isDarkModeActive) {
            this.toggleDarkMode();
        }

        this._principal.getAuthenticationState().subscribe((userIdentity) => {
            if (userIdentity) {
                this.name = userIdentity.firstName + ' ' + userIdentity.lastName;
            }
        });
    }

    toggleDarkMode() {
        setFetchMethod(window.fetch);

        if (isDarkReaderEnabled()) {
            disableDarkMode();
        } else {
            enableDarkMode({});
        }
        this.isDarkModeActive = !this.isDarkModeActive;
        localStorage.setItem('isDarkModeActive', String(this.isDarkModeActive));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {}

    searchGroup(term, group): any[] {
        const valuesToReturn = [];
        group.children.forEach((item) => {
            if (item.type === 'collapsable') {
                const innerValues2 = [];
                if (item.children.length > 0) {
                    item.children.forEach((item2) => {
                        if (item2.title.toLowerCase().includes(term.toLowerCase())) {
                            innerValues2.push(item2);
                        }
                    });
                }
                item.children = innerValues2;
                if (item.children.length > 0) {
                    valuesToReturn.push(item);
                }
            } else {
                if (item.title.toLowerCase().includes(term.toLowerCase())) {
                    valuesToReturn.push(item);
                }
            }
        });
        return valuesToReturn;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    help(): void {
        window.open(`https://datainsightshelp.freshdesk.com/support/solutions/65000104319`);
    }

    logout(): void {
        localStorage.setItem('bulkActionStarted', String(false));
        this._loginService.logout(true);
        this._router.navigate([LOGIN_API_URL]);
    }
}
