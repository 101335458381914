import {th} from 'date-fns/locale';

export interface IEntityAction {
    label: string;
    icon?: string;
    action: any;
    isEnabledFor?: any;
    isAIAction?: boolean;
    navigateBefore?: any;
    navigateAfter?: any;
    aiQuery?: any;
    aiQueryLoadingText?: any;
    aiSuggestion?: any;
    aiSuggestionLoadingText?: any;
    resourceName?: any;
    useContextInAction?: any;
    promptContextKeys?: string[];
    useInputInAction?: any;
    promptFormat?: any;
    promptInstructions?: any;
    aiCustomPromptLoadingText?: any;
    identifyPromptResource?: any;
    redirectOnResourceMismatch?: any;
    useParamInAction?: any;
    searchOptions?: any;
    showResponseInTable?: any;
    showResponseInDialog?: any;
    showCommandResultInTable?: any;
    showCommandResultInDialog?: any;
}

export class EntityAction implements IEntityAction {
    public label: string;
    public icon?: string;
    public action: any;
    public aiQuery = false;
    public aiSuggestion = false;
    public useContextInAction = false;
    public isAIAction = true;
    public useInputInAction = false;
    public identifyPromptResource = false;
    public isEnabledFor?: any;
    public navigateBefore?: any;
    public navigateAfter?: any;
    public resourceName?: any;
    public promptFormat?: any;
    public promptInstruction?: any;
    public promptContextKeys?: string[];
    public aiQueryLoadingText?: any;
    public aiSuggestionLoadingText?: any;
    public aiCustomPromptLoadingText?: any;
    public redirectOnResourceMismatch?: any;
    public useParamInAction?: any;
    public searchOptions?: any;
    public showResponseInTable?: any;
    public showResponseInDialog?: any;
    public showCommandResultInTable?: any;
    public showCommandResultInDialog?: any;

    constructor(action?) {
        this.label = action.label;
        this.icon = action.icon;
        this.action = action.action;
        this.isEnabledFor = action.isEnabledFor ? action.isEnabledFor : (data?) => true;
        this.isAIAction = action.isAIAction;
        this.aiQuery = action.aiQuery ? action.aiQuery : false;
        this.aiSuggestion = action.aiSuggestion ? action.aiSuggestion : false;
        this.resourceName = action.resourceName;
        this.promptFormat = action.promptFormat;
        this.promptInstruction = action.promptInstruction;
        this.promptContextKeys = action.promptContextKeys;
        this.useInputInAction = action.useInputInAction;
        this.useContextInAction = action.useContextInAction;
        this.identifyPromptResource = action.identifyPromptResource;
        this.navigateBefore = action.navigateBefore;
        this.navigateAfter = action.navigateAfter;
        this.aiQueryLoadingText = action.aiQueryLoadingText;
        this.aiSuggestionLoadingText = action.aiSuggestionLoadingText;
        this.aiCustomPromptLoadingText = action.aiCustomPromptLoadingText;
        this.useParamInAction = action.useParamInAction;
        this.redirectOnResourceMismatch = action.redirectOnResourceMismatch;
        this.searchOptions = action.searchOptions;
        this.showResponseInTable = action.showResponseInTable;
        this.showResponseInDialog = action.showResponseInDialog;
        this.showCommandResultInTable = action.showCommandResultInTable;
        this.showCommandResultInDialog = action.showCommandResultInDialog;
    }
}
