// sqs.service.ts
import {Injectable} from '@angular/core';
import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    tenant: any;
    userId: any;
    role: any;
    authority: any;
    name: any;
    email: any;
    tabId: any;
    constructor(private newrelicAgent?: BrowserAgent) {}

    log(action: any, metadata: any): void {
        try {
            this.newrelicAgent?.addPageAction(action, {
                ...metadata
            });
        } catch (error) {
            console.error(error);
        }
    }

    logDirect(action: any, metadata: any): void {
        try {
            this.newrelicAgent?.addPageAction(action, {
                ...metadata
            });
        } catch (error) {
            console.error(error);
        }
    }
}
