export const locale = {
    lang: 'fr',
    data: {
        NAV: {
            APPLICATIONS: 'Configurer',
            USER_MANAGEMENT: 'fr Users Management',
            ORDERS: 'fr Orders',
            RUNS: 'fr Runs',
            INVENTORY: 'fr Inventory',
            REPORTING: 'fr Inventory',
            IMPEX: 'fr Import Data',
            PODS: {
                TITLE: 'fr Proof of Delivery'
            },
            ORDER_SCHEDULING: 'Run Planner',
            MENU: 'fr Menu',
            SAMPLE: {
                TITLE: 'Configuration de la branche',
                BADGE: '15'
            },
            DB_USER: {
                TITLE: 'DB Users'
            },
            TRANSLATION: {
                TITLE: 'Translation'
            },
            POD_AUDIT: {
                TITLE: 'POD Audit'
            },
            REQUESTS: {
                TITLE: 'Requests'
            },
            GARAGE: {
                TITLE: 'Garage'
            },
            WORKFLOW: {
                TITLE: 'Workflow'
            },
            EMAIL_WORKFLOW: {
                TITLE: 'Email Workflow'
            },
            CUSTOM_QUERIES: {
                TITLE: 'Custom Queries'
            },
            DRIVER_START_DAY_LOG: {
                TITLE: 'Driver Start Day Log'
            },
            DRIVER_MESSAGE: {
                TITLE: 'Driver Messages'
            },
            ROLES: {
                TITLE: 'Roles'
            },
            ACCOUNTING: {
                TITLE: 'Accounting'
            },
            ATTEMPTED_DELIVERY: {
                TITLE: 'Outstanding Goods Refusal'
            },
            OUTSTANDING_UNLOADED_ITEMS: {
                TITLE: 'Outstanding Unloaded Items'
            },
            WAREHOUSE: {
                TITLE: "Configuration de l'entrepôt"
            },
            SUPPLIERS: {
                TITLE: 'Suppliers'
            },
            MAJOR_ACCOUNT_CODE: {
                TITLE: 'Codes de compte majeurs'
            },
            SERVICE_PRIORITY_CODE: {
                TITLE: 'Codes de priorité de service'
            },
            DEPOSIT_ITEM: {
                TITLE: 'Articles de dépôt'
            },
            STATEMENT_CODE: {
                TITLE: 'Codes de déclaration'
            },
            DUNNING_MESSAGE_CODE: {
                TITLE: 'Codes de message de relance'
            },
            CREDIT_CLASS: {
                TITLE: 'Classe de crédit'
            },
            CREDIT_TERM: {
                TITLE: 'fr Credit Terms'
            },
            DELIVERY_POINT: {
                TITLE: 'Points de livraison'
            },
            STOP_TYPE: {
                TITLE: "Types d'arrêt"
            },
            PAYMENT_TYPE: {
                TITLE: 'Types de paiement'
            },
            PACKAGE_RETURNS: {
                TITLE: 'Package Returns'
            },
            CUSTOMER: {
                TITLE: 'Les clients'
            },
            CUSTOMER_GROUP: {
                TITLE: 'fr Customer Groups'
            },
            PRICE_LIST: {
                TITLE: 'fr Price Lists'
            },
            PRODUCT_CLASS: {
                TITLE: 'fr Product Class'
            },
            PRODUCT_LIST: {
                TITLE: 'fr Product Lists'
            },
            PRODUCT_GROUP: {
                TITLE: 'fr Product Group'
            },
            UNIT_OF_MEASURE: {
                TITLE: 'fr Unit of Measure'
            },
            PACKAGING: {
                TITLE: 'fr Packaging'
            },
            PRODUCT_TAX_CATEGORY: {
                TITLE: 'fr Product Tax Categories'
            },
            PRODUCT: {
                TITLE: 'fr Products'
            },
            DELIVERY_PACKAGE_TYPE: {
                TITLE: 'fr Delivery Package Types'
            },
            VEHICLE: {
                TITLE: 'fr Vehicles'
            },
            VEHICLE_TYPE: {
                TITLE: 'fr Vehicle Types'
            },
            ROUTE_TYPE: {
                TITLE: 'fr Route Types'
            },
            ROUTE_MAP_SYMBOL: {
                TITLE: 'fr Route Map Symbols'
            },
            CORE_REPORTS: {
                TITLE: 'fr Core Reports'
            },
            ROUTE: {
                TITLE: 'fr Routes'
            },
            ORDER: {
                TITLE: 'fr Orders'
            },
            SALE_ORDER: {
                TITLE: 'fr Sale Orders'
            },
            ORDER_REJECTION_CODE: {
                TITLE: 'fr Order Rejection Codes'
            },
            INCOMING_INVENTORY: {
                TITLE: 'fr Receive/Adjust Inventory'
            },
            LOAD_ORDER: {
                TITLE: 'fr Load Orders'
            },
            PICK_ORDER: {
                TITLE: 'fr Pick Orders'
            },
            PACK_ORDER: {
                TITLE: 'fr Pack Orders'
            },
            DELIVERY_ORDER: {
                TITLE: 'fr Delivery Orders'
            },
            CUSTOMER_SMS_TEXT: {
                TITLE: 'fr Customer SMS Texts'
            },
            EMPLOYEE: {
                TITLE: 'fr Employees'
            },
            EMPLOYEE_GROUP: {
                TITLE: 'fr Employee Groups'
            },
            HANDHELD_PROFILE: {
                TITLE: 'fr Handheld Profiles'
            },
            IMPORT_DATA: {
                TITLE: 'fr Import Data'
            },
            IMPORT_ORDER: {
                TITLE: 'fr Import Order'
            },
            ROUTE_PLANNER: {
                TITLE: 'fr Route Planner'
            },
            ROUTE_COMMAND: {
                TITLE: 'fr Route Monitor'
            },
            INVENTORY_STOCK: {
                TITLE: 'fr Inventory Stock'
            },
            VEHICLE_UNLOADS: {
                TITLE: 'Vehicle Unloads'
            },
            COMPANY: {
                TITLE: 'fr Company'
            },
            REPORT: {
                TITLE: 'fr Reports'
            },
            INVOICE: {
                TITLE: 'fr Invoices'
            },
            HAULIER_ORDER_ALL: {
                TITLE: 'fr Orders'
            },
            HAULIER_ORDER_COLLECTIONS: {
                TITLE: 'fr Collections'
            },
            HAULIER_ORDER_DELIVERIES: {
                TITLE: 'fr Deliveries'
            },
            LOCATION_POINT_AWAITING_INTEGRATION: {
                TITLE: 'Points for Integration'
            },
            HAULIER_ORDER_UNPLANNED: {
                TITLE: 'fr Orders for Planning'
            },
            ORGANISATION: {
                TITLE: 'Organisations'
            },
            CONTACT_INFO: {
                TITLE: 'Contact Info'
            },
            STOCK_TAKES: {
                TITLE: 'fr Stock take'
            },
            RUN: {
                TITLE: 'fr Runs'
            },
            TOUR_PLAN: {
                TITLE: 'fr Tour Planning'
            },
            TP_ASSET_PROFILE: {
                TITLE: 'fr Asset profiles'
            },
            TP_VEHICLE_SKILL: {
                TITLE: 'fr Skills'
            },
            TP_VEHICLE_TYPE: {
                TITLE: 'fr Tp Vehicle Types'
            },
            TOUR_PLANNER: {
                TITLE: 'TourPlanner'
            },
            TRAFFIC_SHEET: {
                TITLE: 'Traffic Sheet'
            },
            DASHBOARD: {
                TITLE: 'fr Dashboard'
            },
            DAILY_MANIFEST: {
                TITLE: 'fr Daily Manifest'
            },
            DEDICATED_VEHICLE_VIEW: {
                TITLE: 'fr Dedicated Vehicle View'
            },
            ARRIVAL_BOARD: {
                TITLE: 'Arrival Board'
            },
            LATE_REASON: {
                TITLE: 'fr Late Reasons'
            },
            CLAUSED_DELIVERY_REASON: {
                TITLE: 'fr Claused Delivery Reasons'
            },
            APP_REQUESTS: {
                TITLE: 'fr App Requests'
            },
            LOCALCLIENT: {
                TITLE: 'Local Client'
            },
            ACCOUNTS_PACKAGE: {
                TITLE: 'fr Accounts Package'
            },
            IMAGE_MANAGEMENT: {
                TITLE: 'fr Image Management'
            },
            // TASK_MANAGEMENT: {
            //     TITLE: 'fr Task Management'
            // },
            // FILE_MANAGEMENT: {
            //     TITLE: 'fr File Management'
            // },
            DRIVER_SUB_TYPES: {
                TITLE: 'fr Driver Sub Types'
            },
            FLEETVIEW: {
                TITLE: 'Fleetview'
            },
            MANIFEST: {
                TITLE: 'Manifest'
            },
            SHIFT_PLANNING: {
                TITLE: 'fr Shift Planning'
            },
            SHIFT_TYPES: {
                TITLE: 'fr Shift Types'
            },
            HAULIER_ORDER_AWAITING_INTEGRATION: {
                TITLE: 'fr Orders Awaiting Integration'
            },
            HAULIER_ORDER_FAILED_INTEGRATION: {
                TITLE: 'fr Orders Failed Integration'
            },
            INTEGRATION_MANAGEMENT: {
                TITLE: 'Integration Management'
            },
            INTEGRATION_MAPPINGS: {
                TITLE: 'Integration Mappings'
            },
            ORDER_PLANNER: {
                TITLE: 'fr Order Planner'
            }
        }
    }
};
