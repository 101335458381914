export function metresToMiles(metres: number): number {
    return metres > 0 ? Math.round(metres / 1609.344) : 0;
}

export function metresToKilometres(metres: number): number {
    return metres > 0 ? Math.round(metres / 1000) : 0;
}

export function roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
}

export function convertToTitleCase(input: string): string {
    return input
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
        .replace(/_/g, ' ') // Replace underscores with spaces
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export function createNestedObject(path: string, value: any): any {
    const keys = path.split('.');
    const result = {};
    let current = result;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (i === keys.length - 1) {
            current[key] = value;
        } else {
            current[key] = {};
            current = current[key];
        }
    }

    return result;
}