import {NzFormModule} from 'ng-zorro-antd/form';
import {NzInputModule} from 'ng-zorro-antd/input';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {DiFormsModule} from 'app/common/di-forms/di-forms.module';
import {DiSearchBarModule} from 'app/common/di-search-bar/di-search-bar.module';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {ToolbarComponent} from 'app/layout/components/toolbar/toolbar.component';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzButtonModule} from 'ng-zorro-antd/button';

import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {NzProgressModule} from 'ng-zorro-antd/progress';
import {PerfectScrollbarModule} from 'perfect-scrollbar-angular';
import {TranslateModule} from '@ngx-translate/core';
import {Resource} from 'app/constants/resource';
import {DiInlineEntityLibsModule} from 'app/common/di-inline-entity-libs/di-inline-entity-libs.module';
import {DraggableUtilsModule} from 'app/blocks/util/drag-and-drop/draggable-utils/draggable-utils.module';
import {CheckRouteModalModule} from 'app/main/private/common/check-route-modal/check-route-modal.module';
import { AIInputModule } from 'app/common/ai-input/ai-input.module';

@NgModule({
    declarations: [ToolbarComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        NzBadgeModule,
        NzToolTipModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        NzDropDownModule,
        NzButtonModule,
        NzDividerModule,
        FontAwesomeModule,
        SatPopoverModule,
        PerfectScrollbarModule,
        DiFormsModule,
        TranslateModule,
        NzInputModule,
        NzFormModule,
        DiInlineEntityLibsModule,
        DraggableUtilsModule,
        NzProgressModule,
        CheckRouteModalModule,
        AIInputModule
    ],
    exports: [ToolbarComponent],
    providers: [
        {
            provide: TRANSLATION_BASE,
            useValue: Resource.TOOLBAR.toString()
        }
    ]
})
export class ToolbarModule {}
