import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {faBuilding} from '@fortawesome/free-solid-svg-icons';
import {IGoodsInfo, GoodsInfo} from 'app/blocks/model/goods-info.model';
import {Resource} from 'app/constants/resource';

import {ENTITIES_SETUP_BASE_URL} from 'app/app.constants';
import {EntityNavigator} from 'app/blocks/routing/navigator';
import {EntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {OutstandingUnloadedItemsService} from 'app/blocks/service/api/outstanding-unloaded-items.service';

@Injectable({
    providedIn: 'root'
})
export class OutstandingUnloadedItemsResource extends AbstractEntityResource<IGoodsInfo> {
    constructor(outstandingUnloadedItemsService: OutstandingUnloadedItemsService, router: Router) {
        super(
            Resource.OUTSTANDING_UNLOADED_ITEMS,
            outstandingUnloadedItemsService,
            new EntityNavigator(ENTITIES_SETUP_BASE_URL, Resource.OUTSTANDING_UNLOADED_ITEMS, router),
            new EntityUrlProvider(ENTITIES_SETUP_BASE_URL, Resource.OUTSTANDING_UNLOADED_ITEMS),
            (entity?) => new GoodsInfo(),
            (entity) => entity.id,
            faBuilding
        );
    }
}
