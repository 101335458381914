import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IGoodsInfo, GoodsInfo} from 'app/blocks/model/goods-info.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {ErrorSeverity, createRequestContext, createRequestOption} from 'app/blocks/util/request-util';

@Injectable({
    providedIn: 'root'
})
export class OutstandingUnloadedItemsService extends EntityCrudService<IGoodsInfo> {
    constructor(http: HttpClient) {
        super(http, Resource.OUTSTANDING_UNLOADED_ITEMS, (obj?) => new GoodsInfo(obj));
    }

    getAllOutstandingUnloadedItems(req?: any): Promise<any> {
        const options = createRequestOption(req);
        console.log('req', req);
        console.log('options', options);
        return this.http.get<IGoodsInfo[]>(`${SERVER_API_URL}api/outstanding-unloaded-items`, {params: options, observe: 'response'}).toPromise();
    }
}
