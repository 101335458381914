import {Resource} from 'app/constants/resource';
export const AIResource = {
    [Resource.HAULIER_ORDERS]: {
        openEntity: {
            searchOptions: {
                id: {
                    required: false,
                    repeatable: false,
                    function: 'id()'
                },
                orderNo: {
                    required: false,
                    repeatable: false,
                    function: 'orderNo()'
                }
            }
        },
        createEntity: {
            searchOptions: {
                customer: {
                    required: true,
                    repeatable: false,
                    function: 'customer()'
                },
                deliveryPoint: {
                    required: false,
                    repeatable: false,
                    function: 'deliveryPoint()'
                },
            },
            synonyms: ['addEntity', 'createEntity', 'generateEntity']
        },
        saveEntity: {},
        saveAndApproveEntity: {},
        selectEntities: {
            searchOptions: {
                id: {
                    required: false,
                    repeatable: true,
                    function: 'id()'
                },
                condition: {
                    required: false,
                    repeatable: true,
                    function: 'condition()'
                }
            }
        },
        deSelectEntities: {
            searchOptions: {
                id: {
                    required: false,
                    repeatable: true,
                    function: 'id()'
                },
                condition: {
                    required: false,
                    repeatable: true,
                    function: 'condition()'
                }
            }
        }
    },
};

export function currentDate(): string {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, '0');
    return '(' + `${yyyy}-${mm}-${dd}` + ')';
}

export function currentTime(): string {
    const today = new Date();
    const hh = String(today.getHours() + 1).padStart(2, '0'); // Months are zero-based
    const mm = String(today.getMinutes()).padStart(2, '0');
    return '(' + `${hh}-${mm}` + ')';
}

export const querySearchOptions = {
    column: {
        required: false,
        repeatable: true,
        function: 'column()'
    },
    resource: {
        required: false,
        repeatable: false,
        function: 'resource()'
    },
    condition: {
        required: false,
        repeatable: true,
        function: 'condition()'
    }
};
