import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzInputModule} from 'ng-zorro-antd/input';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AIInputComponent} from 'app/common/ai-input/ai-input.component';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {SatPopoverModule} from '@ncstate/sat-popover';
import {NzEmptyModule} from 'ng-zorro-antd/empty';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import { DiEntityLibsModule } from 'app/common/di-entity-libs/di-entity-libs.module';

@NgModule({
    declarations: [AIInputComponent],
    imports: [
        CommonModule,
        TranslateModule,
        NzIconModule,
        NzInputModule,
        FontAwesomeModule,
        NzToolTipModule,
        FormsModule,
        ReactiveFormsModule,
        SatPopoverModule,
        NzEmptyModule,
        NzFormModule,
        FontAwesomeModule,
        NzTabsModule,
        DiEntityLibsModule
    ],
    exports: [AIInputComponent],
    providers: []
})
export class AIInputModule {}
