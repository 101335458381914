import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Principal} from 'app/core/auth/principal.service';
import {UnsubscribeDirective} from 'app/common/unsubscribe.directive';
import {environment} from 'environments/environment';
import { ServerEventService } from 'app/blocks/service/server-event.service';
import { parse } from 'path';

@Injectable({
    providedIn: 'root'
})
export class LoggedInService extends UnsubscribeDirective {
    public loggedInData = new BehaviorSubject<any>([]);
    constructor(
        private db: AngularFireDatabase,
        private serverEventService: ServerEventService
    ) {
        super();
        this.serverEventService.loggedInCount.pipe(debounceTime(1000)).subscribe((data) => {
            this.updateLogin();
        });
        this.serverEventService.identity.subscribe((data) => {
            this.updateLogin();
        })
    }

    updateLogin() {
        if (this.serverEventService.identity.getValue().login && this.serverEventService.identity.getValue().tenantId) {
            this.db.database.ref(environment.firebaseConfig.env + '/events/' + this.serverEventService.identity.getValue().tenantId + '/loggedInData/' + this.serverEventService.identity.getValue().login).set(Math.floor(Date.now() / 1000));
            this.db.database.ref(environment.firebaseConfig.env + '/events/' + this.serverEventService.identity.getValue().tenantId + '/loggedInData').get().then((snapshot) => {
                const data = snapshot.val();
                const result = [];
                for (const key of Object.keys(data)) {
                    result.push({
                        user: key,
                        lastLogin: this.formatTimestamp(parseInt(data[key]))
                    });
                }
                console.log(result);
                this.loggedInData.next(result);
            });
        }
    }

    formatTimestamp(timestamp: number): string {
        const date = new Date(timestamp * 1000);
        const day = this.padZero(date.getDate());
        const month = this.padZero(date.getMonth() + 1);
        const year = date.getFullYear().toString().slice(-2);
        const hours = this.padZero(date.getHours());
        const minutes = this.padZero(date.getMinutes());
        return `${day}/${month}/${year}:${hours}:${minutes}`;
    }
    
    padZero(num: number): string {
        return num < 10 ? '0' + num : num.toString();
    }
}
