import {Injectable} from '@angular/core';
import {EntityAction} from 'app/blocks/model/action.model';
import {EntityResource} from 'app/blocks/resource/entity-resource';
import {Resource} from 'app/constants/resource';
import {adminNavigation} from 'app/navigation/admin-navigation';
import {convertToTitleCase} from 'app/blocks/util/conversion-util';
import Fuse from 'fuse.js';
import {FuseNavigation} from '@fuse/types';
import {RoleService} from 'app/blocks/service/api/role.service';
import {AIResource} from 'app/constants/ai';
import {max, min} from 'lodash';
import {Store} from '@ngxs/store';
import {AICommandService} from 'app/blocks/service/ai-command.service';

@Injectable({
    providedIn: 'root'
})
export class AISearchService {
    private fieldCommands = [];
    private screenCommands = [];
    private defaultCommands = [];
    private totalCommands = [];
    private commandSynonyms = {};
    public screenResource = null;

    private fuseHistory: {[key: string]: {validityUpto: number}[]} = {};
    public searchOptions = {};
    public searchCache = {};
    public screenSearchOptions = {};

    private fuse: Fuse<any> = new Fuse([], {
        threshold: 0.2,
        useExtendedSearch: true,
        keys: ['synonyms'],
        sortFn: (a, b) => {
            const scoreA = this.computeHistoryScore(a.matches[0].value);
            const scoreB = this.computeHistoryScore(b.matches[0].value);
            const isCurrentScreenItemA = this.isCurrentScreenItem(a.matches[0].value);
            const isCurrentScreenItemB = this.isCurrentScreenItem(b.matches[0].value);

            if (isCurrentScreenItemA > 0 && isCurrentScreenItemB > 0) {
                return isCurrentScreenItemB - isCurrentScreenItemA;
            }

            if (isCurrentScreenItemA > 0) {
                return -1;
            }

            if (isCurrentScreenItemB > 0) {
                return 1;
            }

            if (scoreA > 0 && scoreB > 0) {
                return scoreB - scoreA;
            }
            if (scoreA > 0) {
                return -1;
            }
            if (scoreB > 0) {
                return 1;
            }
            return a.score - b.score;
        }
    });

    constructor(
        private entityResource: EntityResource,
        private _store: Store,
        private roleService: RoleService,
        private aiCommandService: AICommandService
    ) {
        for (let command of this.aiCommandService.getCommands()) {
            const commandName = convertToTitleCase(command.label);
            this.searchOptions[commandName] = command.searchOptions;
            this.defaultCommands.push(commandName);
        }

        for (let resource of Object.keys(AIResource)) {
            const resourceName = convertToTitleCase(resource);
            for (let command of Object.keys(AIResource[resource])) {
                const commandName = convertToTitleCase(command).replace(/Entities|Entity/g, resourceName);
                this.searchOptions[commandName] = AIResource[resource][command]?.searchOptions;
                this.defaultCommands.push(commandName);
                if (AIResource[resource][command]?.synonyms) {
                    this.commandSynonyms[commandName] = [];
                    for (let synonym of AIResource[resource][command].synonyms) {
                        const synonymName = convertToTitleCase(synonym).replace(/Entities|Entity/g, resourceName);
                        this.commandSynonyms[commandName].push(synonymName);
                    }
                }
            }
        }

        for (let screen of this.getMenuList(adminNavigation).map((menu) => menu.title)) {
            this.defaultCommands.push('Open ' + screen + ' Screen');
        }

        this.totalCommands = this.defaultCommands
            .concat(this.screenCommands)
            .concat(this.fieldCommands)
            .filter((value, index, self) => self.indexOf(value) === index);

        this.loadHistoryFromStorage();
        setInterval(() => {
            this.saveHistoryToStorage();
        }, 60000);
    }

    public setScreenCommands(commands: EntityAction[], resourceName: string): void {
        this.screenCommands = [];
        this.screenSearchOptions = {};
        this.screenResource = convertToTitleCase(resourceName);
        for (const command of commands) {
            if (command.isAIAction) {
                const commandName = convertToTitleCase(command.label).replace(/Entities|Entity/g, convertToTitleCase(resourceName));
                this.screenCommands.push(commandName);
                if (command.searchOptions) {
                    this.screenSearchOptions[commandName] = command.searchOptions;
                }
            }
        }

        this.totalCommands = this.defaultCommands
            .concat(this.screenCommands)
            .concat(this.fieldCommands)
            .filter((value, index, self) => self.indexOf(value) === index);
    }

    public setFieldCommands(fields: string[]): void {
        this.fieldCommands = [];
        for (const field of fields.map((field) => convertToTitleCase(field))) {
            this.fieldCommands.push('Set ' + field + ' value to');
        }
        this.totalCommands = this.defaultCommands
            .concat(this.screenCommands)
            .concat(this.fieldCommands)
            .filter((value, index, self) => self.indexOf(value) === index);
    }

    private isCurrentScreenItem(item: any): number {
        let points = 0;

        if (item.includes(this.screenResource)) {
            points += 1;
        }

        if (this.screenCommands.includes(item)) {
            points += 1;
        }

        if (this.fieldCommands.includes(item)) {
            points += 1;
        }

        return points;
    }

    private computeHistoryScore(item: any): number {
        if (this.fuseHistory[item]) {
            return this.fuseHistory[item].length;
        }
        return 0;
    }

    public addHistory(item: string): void {
        const validityPeriod = 7 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const validityUpto = Date.now() + validityPeriod;
        if (!(this.fuseHistory[item] instanceof Array)) {
            this.fuseHistory[item] = [];
        }
        this.fuseHistory[item].push({validityUpto});
    }

    private saveHistoryToStorage(): void {
        const currentTime = Date.now();

        // Remove expired entries
        for (const item in this.fuseHistory) {
            this.fuseHistory[item] = this.fuseHistory[item].filter((entry) => entry.validityUpto > currentTime);
            if (this.fuseHistory[item]?.length === 0) {
                delete this.fuseHistory[item];
            }
            if (this.fuseHistory[item]?.length > 50) {
                this.fuseHistory[item].sort((a, b) => b.validityUpto - a.validityUpto);
                this.fuseHistory[item] = this.fuseHistory[item].slice(0, 50);
            }
        }

        // Save the combined history back to local storage
        localStorage.setItem('fuseHistory', JSON.stringify(this.fuseHistory));
    }

    private loadHistoryFromStorage(): void {
        const storedHistory = localStorage.getItem('fuseHistory');
        if (storedHistory) {
            this.fuseHistory = JSON.parse(storedHistory);
        }
    }

    search(searchType, resourceName: Resource, query: string, displayKey?: string, size?: number): Promise<any[]> {
        return this.entityResource
            .getResource(resourceName)
            .api.searchHttp({
                page: 0,
                query: query,
                sort: 'id,asc',
                size: size || 8
            })
            .then((response) => {
                if (displayKey) {
                    return this.saveToSearchCache(searchType, response.body, displayKey);
                } else {
                    return this.saveToSearchCache(searchType, response.body);
                }
            });
    }

    fuzzySearch(query, values, synonyms = {}): Promise<any[]> {
        const searchData = values.map((value) => ({name: value, synonyms: synonyms[value] || [value]}));
        this.fuse.setCollection(searchData);
        const results = this.fuse.search(query).map((result) => result.item.name);
        if (results.length === 0) {
            return Promise.resolve(values.slice(0, Math.min(8, values.length)));
        }
        return Promise.resolve(results.slice(0, min([8, results.length])));
    }

    getFieldSearchFunction(searchType: string): (query: string) => Promise<any[]> {
        switch (searchType) {
            default:
                return null;
        }
    }

    getInputSearchFunction(searchType: string, options = [], parameters = {}): (query: string) => Promise<string[]> {
        switch (searchType) {
            case 'resource':
                return (query) => {
                    return this.fuzzySearch(
                        query,
                        Object.keys(AIResource).map((resource) => convertToTitleCase(resource))
                    );
                };
            case 'command':
                return (query) => {
                    return this.fuzzySearch(query, this.totalCommands, this.commandSynonyms);
                };
            default:
                if (options && options.length > 0) {
                    return (query) => {
                        return this.fuzzySearch(query, options);
                    };
                } else {
                    return null;
                }
        }
    }

    saveToSearchCache(searchType, results, displayKey = null) {
        const resultMap = {};
        for (let result of results) {
            const key = (displayKey ? result[displayKey] : result).replace(/[^\w\s]/gi, '');
            resultMap[key] = result;
        }
        this.searchCache[searchType] = resultMap;
        return Object.keys(resultMap);
    }

    getValueFromCache(searchType, parameters): any {
        const param = parameters[searchType];
        if (param && this.searchCache[searchType]) {
            return this.searchCache[searchType][param];
        }
        return null;
    }

    getMenuList = (nav: FuseNavigation[]) => {
        let menuList = [];
        let roleSettings = this.roleService.roleSettings.value;
        for (const item of nav) {
            if (item.children) {
                menuList = [...menuList, ...this.getMenuList(item.children)];
            } else {
                if (roleSettings[item.id] && roleSettings[item.id] === false) {
                    continue;
                }
                menuList.push({
                    title: item.title,
                    url: item.url
                });
            }
        }
        return menuList;
    };
}
